import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL;

export const client = axios.create({
	baseURL,
});

export const updateClientHeaders = (token: string) => {
	client.interceptors.request.use(function (config) {
		config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
		return config;
	});
};
