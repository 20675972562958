import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import QRCode from "react-qr-code";
import moment from "moment";
import { useForm } from "react-hook-form";
import { client, updateClientHeaders } from "./client";
import { useNavigate } from "react-router-dom";

const requestForNewPhone = async (): Promise<string> => {
	const {
		data: {
			data: { id },
		},
	} = await client.post("/ww-number", {});
	return id;
};

function App() {
	const navigate = useNavigate();

	useEffect(() => {
		const localToken = window.localStorage.getItem("apiKey");

		if (!localToken) {
			navigate("/login");
			return;
		}

		updateClientHeaders(localToken);
	}, [navigate]);

	const { register, handleSubmit, watch } = useForm<{
		from: string;
		to: string;
		message: string;
		type: "text" | "image";
		image?: string;
	}>({});
	const [numbers, setNumbers] = useState<WWNumber[]>([]);
	const [qrValue, setQRValue] = useState<string | null>(null);
	const socket = io(process.env.REACT_APP_SOCKET_URL as string, {});

	useEffect(() => {
		socket.connect();

		socket.on("qr", (d) => {
			setQRValue(d.qr);
		});
	}, [socket]);

	const joinRoom = (id: string) => {
		socket.emit("client", { id });
	};

	useEffect(() => {
		const fetch = async () => {
			const {
				data: { data },
			} = await client.get(`/ww-number`);
			setNumbers(data);
		};

		fetch();
	}, []);

	const type = watch("type", "text");

	return (
		<div className="mx-auto w-1/2">
			<div className="mx-auto mt-20">
				<h2 className="text-lg font-medium">Phone numbers</h2>
				<table className="table-auto w-full mt-2">
					<thead>
						<tr>
							<th className="border-b text-left">Key</th>
							<th className="border-b text-left">Number</th>
							<th className="border-b text-left">Status</th>
							<th className="border-b text-left">Auth Secured</th>
							<th className="border-b text-left">Last Contact</th>
						</tr>
					</thead>
					<tbody>
						{numbers.map((v) => (
							<tr key={v._id}>
								<td>{v.key}</td>
								<td>{v.number?.formatted}</td>
								<td>{v.status}</td>
								<td>{v.authSecured ? "Yes" : "No"}</td>
								<td>{moment(v.updatedAt).fromNow()}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="mt-16">
				<h2 className="text-lg font-medium">Send Message</h2>
				<form
					className="w-full"
					onSubmit={handleSubmit(async (d) => {
						const body = { from: d.from, to: d.to } as any;
						body["type"] = type;

						if (type === "text") {
							body["content"] = { text: d.message };
						} else if (type === "image") {
							body["content"] = { image: d.image, caption: d.message };
						}

						await client.post("/conversation/send", body);
						alert("Sent");
					})}
				>
					<div className="w-full gap-4 grid grid-cols-2 grid-flow-row">
						<div className="w-full">
							<select className="w-full h-9" {...register("from")}>
								<option value="">Select a key</option>
								{numbers
									.filter((v) => v.status === "connected")
									.map((v) => (
										<option key={v.key} value={v.key}>
											{v.key}
										</option>
									))}
							</select>
						</div>
						<div className="w-full">
							<input
								type="text"
								placeholder="To:"
								className="px-4 py-2 border rounded-xl w-full"
								{...register("to")}
							/>
						</div>
						<div className="w-full">
							<select className="w-full h-9" {...register("type")}>
								<option value={"text"} selected>
									Text
								</option>
								<option value={"image"}>Image</option>
							</select>
						</div>
						{type === "image" && (
							<div className="w-full">
								<input
									type="text"
									placeholder="Image URL"
									className="px-4 py-2 border rounded-xl w-full"
									{...register("image")}
								/>
							</div>
						)}
					</div>
					<div className="flex mt-2">
						<textarea
							className="w-full border rounded-xl px-4 py-2"
							placeholder={type === "text" ? "Message" : "Caption"}
							rows={5}
							{...register("message")}
						></textarea>
					</div>
					<button
						type="submit"
						className="bg-blue-800 px-4 py-2 rounded-xl text-white cursor-pointer w-32 text-center mt-2"
					>
						Send
					</button>
				</form>
			</div>
			<div className="flex flex-col w-full justify-center mt-5 items-center space-y-3">
				<span
					className="bg-blue-800 px-4 py-2 rounded-xl text-white cursor-pointer w-32 text-center"
					onClick={async (e) => {
						e.preventDefault();
						const id = await requestForNewPhone();
						joinRoom(id);
					}}
				>
					Add Phone
				</span>
				<div>{qrValue && <QRCode value={qrValue} />}</div>
			</div>
		</div>
	);
}

export default App;

interface WWNumber {
	_id: string;
	key: string;
	status: string;
	updatedAt: string;
	authSecured: boolean;
	number: { formatted: string };
}
