import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { client } from "./client";

function Login() {
	const navigate = useNavigate();
	const { register, handleSubmit } = useForm<{
		accountId: string;
	}>({});

	return (
		<div className="w-screen h-screen flex items-center justify-center">
			<form
				className="w-96"
				onSubmit={handleSubmit(async (d) => {
					const {
						data: { data },
					} = await client.post("/account/login", d);
					window.localStorage.setItem("apiKey", data);
					navigate("/");
				})}
			>
				<div className="w-full">
					<input
						type="text"
						placeholder="Account Id"
						className="px-4 py-2 border rounded-xl w-full"
						{...register("accountId")}
					/>
				</div>
				<button
					type="submit"
					className="bg-blue-800 px-4 py-2 rounded-xl text-white cursor-pointer w-32 text-center mt-2"
				>
					Login
				</button>
			</form>
		</div>
	);
}

export default Login;
